// Copyright 2021-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

// @generated by protoc-gen-es v1.1.1 with parameter "target=ts,import_extension=none"
// @generated from file kubeappsapis/core/packages/v1alpha1/packages.proto (package kubeappsapis.core.packages.v1alpha1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Any, Message, proto3 } from "@bufbuild/protobuf";
import { Plugin } from "../../plugins/v1alpha1/plugins_pb";

/**
 * GetAvailablePackageSummariesRequest
 *
 * Request for GetAvailablePackageSummaries
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetAvailablePackageSummariesRequest
 */
export class GetAvailablePackageSummariesRequest extends Message<GetAvailablePackageSummariesRequest> {
  /**
   * The context (cluster/namespace) for the request
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * The filters used for the request
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.FilterOptions filter_options = 2;
   */
  filterOptions?: FilterOptions;

  /**
   * Pagination options specifying where to start and how many results to include.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PaginationOptions pagination_options = 3;
   */
  paginationOptions?: PaginationOptions;

  constructor(data?: PartialMessage<GetAvailablePackageSummariesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetAvailablePackageSummariesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "filter_options", kind: "message", T: FilterOptions },
    { no: 3, name: "pagination_options", kind: "message", T: PaginationOptions },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAvailablePackageSummariesRequest {
    return new GetAvailablePackageSummariesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageSummariesRequest {
    return new GetAvailablePackageSummariesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageSummariesRequest {
    return new GetAvailablePackageSummariesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAvailablePackageSummariesRequest
      | PlainMessage<GetAvailablePackageSummariesRequest>
      | undefined,
    b:
      | GetAvailablePackageSummariesRequest
      | PlainMessage<GetAvailablePackageSummariesRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAvailablePackageSummariesRequest, a, b);
  }
}

/**
 * GetAvailablePackageDetailRequest
 *
 * Request for GetAvailablePackageDetail
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetAvailablePackageDetailRequest
 */
export class GetAvailablePackageDetailRequest extends Message<GetAvailablePackageDetailRequest> {
  /**
   * The information required to uniquely
   * identify an available package
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageReference available_package_ref = 1;
   */
  availablePackageRef?: AvailablePackageReference;

  /**
   * Optional specific version (or version reference) to request.
   * By default the latest version (or latest version matching the reference)
   * will be returned.
   *
   * @generated from field: string pkg_version = 2;
   */
  pkgVersion = "";

  constructor(data?: PartialMessage<GetAvailablePackageDetailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.GetAvailablePackageDetailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available_package_ref", kind: "message", T: AvailablePackageReference },
    { no: 2, name: "pkg_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAvailablePackageDetailRequest {
    return new GetAvailablePackageDetailRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageDetailRequest {
    return new GetAvailablePackageDetailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageDetailRequest {
    return new GetAvailablePackageDetailRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAvailablePackageDetailRequest
      | PlainMessage<GetAvailablePackageDetailRequest>
      | undefined,
    b:
      | GetAvailablePackageDetailRequest
      | PlainMessage<GetAvailablePackageDetailRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAvailablePackageDetailRequest, a, b);
  }
}

/**
 * GetAvailablePackageVersionsRequest
 *
 * Request for GetAvailablePackageVersions
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetAvailablePackageVersionsRequest
 */
export class GetAvailablePackageVersionsRequest extends Message<GetAvailablePackageVersionsRequest> {
  /**
   * The information required to uniquely
   * identify an available package
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageReference available_package_ref = 1;
   */
  availablePackageRef?: AvailablePackageReference;

  /**
   * Optional version reference for which full version history is required.  By
   * default a summary of versions is returned as outlined in the response.
   * Plugins can choose not to implement this and provide the summary only, it
   * is provided for completeness only.
   *
   * @generated from field: string pkg_version = 2;
   */
  pkgVersion = "";

  constructor(data?: PartialMessage<GetAvailablePackageVersionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetAvailablePackageVersionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available_package_ref", kind: "message", T: AvailablePackageReference },
    { no: 2, name: "pkg_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAvailablePackageVersionsRequest {
    return new GetAvailablePackageVersionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageVersionsRequest {
    return new GetAvailablePackageVersionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageVersionsRequest {
    return new GetAvailablePackageVersionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAvailablePackageVersionsRequest
      | PlainMessage<GetAvailablePackageVersionsRequest>
      | undefined,
    b:
      | GetAvailablePackageVersionsRequest
      | PlainMessage<GetAvailablePackageVersionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAvailablePackageVersionsRequest, a, b);
  }
}

/**
 * GetInstalledPackageSummariesRequest
 *
 * Request for GetInstalledPackageSummaries
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetInstalledPackageSummariesRequest
 */
export class GetInstalledPackageSummariesRequest extends Message<GetInstalledPackageSummariesRequest> {
  /**
   * The context (cluster/namespace) for the request.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * Pagination options specifying where to start and how many results to include.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PaginationOptions pagination_options = 2;
   */
  paginationOptions?: PaginationOptions;

  constructor(data?: PartialMessage<GetInstalledPackageSummariesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetInstalledPackageSummariesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "pagination_options", kind: "message", T: PaginationOptions },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetInstalledPackageSummariesRequest {
    return new GetInstalledPackageSummariesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageSummariesRequest {
    return new GetInstalledPackageSummariesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageSummariesRequest {
    return new GetInstalledPackageSummariesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInstalledPackageSummariesRequest
      | PlainMessage<GetInstalledPackageSummariesRequest>
      | undefined,
    b:
      | GetInstalledPackageSummariesRequest
      | PlainMessage<GetInstalledPackageSummariesRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetInstalledPackageSummariesRequest, a, b);
  }
}

/**
 * GetInstalledPackageDetailRequest
 *
 * Request for GetInstalledPackageDetail
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetInstalledPackageDetailRequest
 */
export class GetInstalledPackageDetailRequest extends Message<GetInstalledPackageDetailRequest> {
  /**
   * The information required to uniquely
   * identify an installed package
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  constructor(data?: PartialMessage<GetInstalledPackageDetailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.GetInstalledPackageDetailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetInstalledPackageDetailRequest {
    return new GetInstalledPackageDetailRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageDetailRequest {
    return new GetInstalledPackageDetailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageDetailRequest {
    return new GetInstalledPackageDetailRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInstalledPackageDetailRequest
      | PlainMessage<GetInstalledPackageDetailRequest>
      | undefined,
    b:
      | GetInstalledPackageDetailRequest
      | PlainMessage<GetInstalledPackageDetailRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetInstalledPackageDetailRequest, a, b);
  }
}

/**
 * CreateInstalledPackageRequest
 *
 * Request for CreateInstalledPackage
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.CreateInstalledPackageRequest
 */
export class CreateInstalledPackageRequest extends Message<CreateInstalledPackageRequest> {
  /**
   * A reference uniquely identifying the package available for installation.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageReference available_package_ref = 1;
   */
  availablePackageRef?: AvailablePackageReference;

  /**
   * The target context where the package is intended to be installed.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context target_context = 2;
   */
  targetContext?: Context;

  /**
   * A user-provided name for the installed package (eg. project-x-db)
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * For helm this will be the exact version in VersionReference.version
   * For other plugins we can extend the VersionReference as needed.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.VersionReference pkg_version_reference = 4;
   */
  pkgVersionReference?: VersionReference;

  /**
   * An optional serialized values string to be included when templating a package
   * in the format expected by the plugin. Included when the backend format doesn't
   * use secrets or configmaps for values or supports both. These values are layered
   * on top of any values refs above, when relevant.
   *
   * @generated from field: string values = 5;
   */
  values = "";

  /**
   * An optional field for specifying data common to systems that reconcile
   * the package on the cluster.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.ReconciliationOptions reconciliation_options = 6;
   */
  reconciliationOptions?: ReconciliationOptions;

  constructor(data?: PartialMessage<CreateInstalledPackageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.CreateInstalledPackageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available_package_ref", kind: "message", T: AvailablePackageReference },
    { no: 2, name: "target_context", kind: "message", T: Context },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "pkg_version_reference", kind: "message", T: VersionReference },
    { no: 5, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "reconciliation_options", kind: "message", T: ReconciliationOptions },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateInstalledPackageRequest {
    return new CreateInstalledPackageRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateInstalledPackageRequest {
    return new CreateInstalledPackageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateInstalledPackageRequest {
    return new CreateInstalledPackageRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateInstalledPackageRequest | PlainMessage<CreateInstalledPackageRequest> | undefined,
    b: CreateInstalledPackageRequest | PlainMessage<CreateInstalledPackageRequest> | undefined,
  ): boolean {
    return proto3.util.equals(CreateInstalledPackageRequest, a, b);
  }
}

/**
 * UpdateInstalledPackageRequest
 *
 * Request for UpdateInstalledPackage. The intent is to reach the desired state specified
 * by the fields in the request, while leaving other fields intact. This is a whole
 * object "Update" semantics rather than "Patch" semantics. The caller will provide the
 * values for the fields below, which will replace, or be overlaid onto, the
 * corresponding fields in the existing resource. For example, with the
 * UpdateInstalledPackageRequest, it is not possible to change just the 'package version
 * reference' without also specifying 'values' field. As a side effect, not specifying the
 * 'values' field in the request means there are no values specified in the desired state.
 * So the meaning of each field value is describing the desired state of the corresponding
 * field in the resource after the update operation has completed the renconciliation.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.UpdateInstalledPackageRequest
 */
export class UpdateInstalledPackageRequest extends Message<UpdateInstalledPackageRequest> {
  /**
   * A reference uniquely identifying the installed package being updated.
   * Required
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  /**
   * For helm this will be the exact version in VersionReference.version
   * For fluxv2 this could be any semver constraint expression
   * For other plugins we can extend the VersionReference as needed. Optional
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.VersionReference pkg_version_reference = 2;
   */
  pkgVersionReference?: VersionReference;

  /**
   * An optional serialized values string to be included when templating a
   * package in the format expected by the plugin. Included when the backend
   * format doesn't use secrets or configmaps for values or supports both.
   * These values are layered on top of any values refs above, when
   * relevant.
   *
   * @generated from field: string values = 3;
   */
  values = "";

  /**
   * An optional field for specifying data common to systems that reconcile
   * the package on the cluster.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.ReconciliationOptions reconciliation_options = 4;
   */
  reconciliationOptions?: ReconciliationOptions;

  constructor(data?: PartialMessage<UpdateInstalledPackageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.UpdateInstalledPackageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
    { no: 2, name: "pkg_version_reference", kind: "message", T: VersionReference },
    { no: 3, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "reconciliation_options", kind: "message", T: ReconciliationOptions },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdateInstalledPackageRequest {
    return new UpdateInstalledPackageRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdateInstalledPackageRequest {
    return new UpdateInstalledPackageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdateInstalledPackageRequest {
    return new UpdateInstalledPackageRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateInstalledPackageRequest | PlainMessage<UpdateInstalledPackageRequest> | undefined,
    b: UpdateInstalledPackageRequest | PlainMessage<UpdateInstalledPackageRequest> | undefined,
  ): boolean {
    return proto3.util.equals(UpdateInstalledPackageRequest, a, b);
  }
}

/**
 * DeleteInstalledPackageRequest
 *
 * Request for DeleteInstalledPackage
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.DeleteInstalledPackageRequest
 */
export class DeleteInstalledPackageRequest extends Message<DeleteInstalledPackageRequest> {
  /**
   * A reference to uniquely identify the installed package to be deleted.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  constructor(data?: PartialMessage<DeleteInstalledPackageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.DeleteInstalledPackageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteInstalledPackageRequest {
    return new DeleteInstalledPackageRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteInstalledPackageRequest {
    return new DeleteInstalledPackageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteInstalledPackageRequest {
    return new DeleteInstalledPackageRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteInstalledPackageRequest | PlainMessage<DeleteInstalledPackageRequest> | undefined,
    b: DeleteInstalledPackageRequest | PlainMessage<DeleteInstalledPackageRequest> | undefined,
  ): boolean {
    return proto3.util.equals(DeleteInstalledPackageRequest, a, b);
  }
}

/**
 * GetInstalledPackageResourceRefsRequest
 *
 * Request for GetInstalledPackageResourceRefs
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetInstalledPackageResourceRefsRequest
 */
export class GetInstalledPackageResourceRefsRequest extends Message<GetInstalledPackageResourceRefsRequest> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  constructor(data?: PartialMessage<GetInstalledPackageResourceRefsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetInstalledPackageResourceRefsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetInstalledPackageResourceRefsRequest {
    return new GetInstalledPackageResourceRefsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageResourceRefsRequest {
    return new GetInstalledPackageResourceRefsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageResourceRefsRequest {
    return new GetInstalledPackageResourceRefsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInstalledPackageResourceRefsRequest
      | PlainMessage<GetInstalledPackageResourceRefsRequest>
      | undefined,
    b:
      | GetInstalledPackageResourceRefsRequest
      | PlainMessage<GetInstalledPackageResourceRefsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetInstalledPackageResourceRefsRequest, a, b);
  }
}

/**
 * GetAvailablePackageSummariesResponse
 *
 * Response for GetAvailablePackageSummaries
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{"available_package_summaries": [{}]}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetAvailablePackageSummariesResponse
 */
export class GetAvailablePackageSummariesResponse extends Message<GetAvailablePackageSummariesResponse> {
  /**
   * Available packages summaries
   *
   * List of AvailablePackageSummary
   *
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.AvailablePackageSummary available_package_summaries = 1;
   */
  availablePackageSummaries: AvailablePackageSummary[] = [];

  /**
   * Next page token
   *
   * This field represents the pagination token to retrieve the next page of
   * results. If the value is "", it means no further results for the request.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * Categories
   *
   * This optional field contains the distinct category names considering the FilterOptions.
   *
   * @generated from field: repeated string categories = 3;
   */
  categories: string[] = [];

  constructor(data?: PartialMessage<GetAvailablePackageSummariesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetAvailablePackageSummariesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "available_package_summaries",
      kind: "message",
      T: AvailablePackageSummary,
      repeated: true,
    },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAvailablePackageSummariesResponse {
    return new GetAvailablePackageSummariesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageSummariesResponse {
    return new GetAvailablePackageSummariesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageSummariesResponse {
    return new GetAvailablePackageSummariesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAvailablePackageSummariesResponse
      | PlainMessage<GetAvailablePackageSummariesResponse>
      | undefined,
    b:
      | GetAvailablePackageSummariesResponse
      | PlainMessage<GetAvailablePackageSummariesResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAvailablePackageSummariesResponse, a, b);
  }
}

/**
 * GetAvailablePackageDetailResponse
 *
 * Response for GetAvailablePackageDetail
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{"available_package_detail": [{}]}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetAvailablePackageDetailResponse
 */
export class GetAvailablePackageDetailResponse extends Message<GetAvailablePackageDetailResponse> {
  /**
   * Available package detail
   *
   * The requested AvailablePackageDetail
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageDetail available_package_detail = 1;
   */
  availablePackageDetail?: AvailablePackageDetail;

  constructor(data?: PartialMessage<GetAvailablePackageDetailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetAvailablePackageDetailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available_package_detail", kind: "message", T: AvailablePackageDetail },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAvailablePackageDetailResponse {
    return new GetAvailablePackageDetailResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageDetailResponse {
    return new GetAvailablePackageDetailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageDetailResponse {
    return new GetAvailablePackageDetailResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAvailablePackageDetailResponse
      | PlainMessage<GetAvailablePackageDetailResponse>
      | undefined,
    b:
      | GetAvailablePackageDetailResponse
      | PlainMessage<GetAvailablePackageDetailResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAvailablePackageDetailResponse, a, b);
  }
}

/**
 * GetAvailablePackageVersionsResponse
 *
 * Response for GetAvailablePackageVersions
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{"package_app_versions": [{}]}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetAvailablePackageVersionsResponse
 */
export class GetAvailablePackageVersionsResponse extends Message<GetAvailablePackageVersionsResponse> {
  /**
   * Package app versions
   *
   * By default (when version_query is empty or ignored) the response
   * should contain an ordered summary of versions including the most recent three
   * patch versions of the most recent three minor versions of the most recent three
   * major versions when available, something like:
   * [
   *   { pkg_version: "10.3.19", app_version: "2.16.8" },
   *   { pkg_version: "10.3.18", app_version: "2.16.8" },
   *   { pkg_version: "10.3.17", app_version: "2.16.7" },
   *   { pkg_version: "10.2.6", app_version: "2.15.3" },
   *   { pkg_version: "10.2.5", app_version: "2.15.2" },
   *   { pkg_version: "10.2.4", app_version: "2.15.2" },
   *   { pkg_version: "10.1.8", app_version: "2.13.5" },
   *   { pkg_version: "10.1.7", app_version: "2.13.5" },
   *   { pkg_version: "10.1.6", app_version: "2.13.5" },
   *   { pkg_version: "9.5.4", app_version: "2.8.9" },
   *   ...
   *   { pkg_version: "8.2.5", app_version: "1.19.5" },
   *   ...
   * ]
   * If a version_query is present and the plugin chooses to support it,
   * the full history of versions matching the version query should be returned.
   *
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.PackageAppVersion package_app_versions = 1;
   */
  packageAppVersions: PackageAppVersion[] = [];

  constructor(data?: PartialMessage<GetAvailablePackageVersionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetAvailablePackageVersionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_app_versions", kind: "message", T: PackageAppVersion, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAvailablePackageVersionsResponse {
    return new GetAvailablePackageVersionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageVersionsResponse {
    return new GetAvailablePackageVersionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAvailablePackageVersionsResponse {
    return new GetAvailablePackageVersionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAvailablePackageVersionsResponse
      | PlainMessage<GetAvailablePackageVersionsResponse>
      | undefined,
    b:
      | GetAvailablePackageVersionsResponse
      | PlainMessage<GetAvailablePackageVersionsResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAvailablePackageVersionsResponse, a, b);
  }
}

/**
 * GetInstalledPackageSummariesResponse
 *
 * Response for GetInstalledPackageSummaries
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetInstalledPackageSummariesResponse
 */
export class GetInstalledPackageSummariesResponse extends Message<GetInstalledPackageSummariesResponse> {
  /**
   * Installed packages summaries
   *
   * List of InstalledPackageSummary
   *
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.InstalledPackageSummary installed_package_summaries = 1;
   */
  installedPackageSummaries: InstalledPackageSummary[] = [];

  /**
   * Next page token
   *
   * This field represents the pagination token to retrieve the next page of
   * results. If the value is "", it means no further results for the request.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<GetInstalledPackageSummariesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetInstalledPackageSummariesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "installed_package_summaries",
      kind: "message",
      T: InstalledPackageSummary,
      repeated: true,
    },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetInstalledPackageSummariesResponse {
    return new GetInstalledPackageSummariesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageSummariesResponse {
    return new GetInstalledPackageSummariesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageSummariesResponse {
    return new GetInstalledPackageSummariesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInstalledPackageSummariesResponse
      | PlainMessage<GetInstalledPackageSummariesResponse>
      | undefined,
    b:
      | GetInstalledPackageSummariesResponse
      | PlainMessage<GetInstalledPackageSummariesResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetInstalledPackageSummariesResponse, a, b);
  }
}

/**
 * GetInstalledPackageDetailResponse
 *
 * Response for GetInstalledPackageDetail
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{"installed_package_detail": [{}]}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetInstalledPackageDetailResponse
 */
export class GetInstalledPackageDetailResponse extends Message<GetInstalledPackageDetailResponse> {
  /**
   * InstalledPackageDetail
   *
   * The requested InstalledPackageDetail
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageDetail installed_package_detail = 1;
   */
  installedPackageDetail?: InstalledPackageDetail;

  constructor(data?: PartialMessage<GetInstalledPackageDetailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetInstalledPackageDetailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_detail", kind: "message", T: InstalledPackageDetail },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetInstalledPackageDetailResponse {
    return new GetInstalledPackageDetailResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageDetailResponse {
    return new GetInstalledPackageDetailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageDetailResponse {
    return new GetInstalledPackageDetailResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInstalledPackageDetailResponse
      | PlainMessage<GetInstalledPackageDetailResponse>
      | undefined,
    b:
      | GetInstalledPackageDetailResponse
      | PlainMessage<GetInstalledPackageDetailResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetInstalledPackageDetailResponse, a, b);
  }
}

/**
 * CreateInstalledPackageResponse
 *
 * Response for CreateInstalledPackage
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{"installed_package_ref": {}}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.CreateInstalledPackageResponse
 */
export class CreateInstalledPackageResponse extends Message<CreateInstalledPackageResponse> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  constructor(data?: PartialMessage<CreateInstalledPackageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.CreateInstalledPackageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateInstalledPackageResponse {
    return new CreateInstalledPackageResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateInstalledPackageResponse {
    return new CreateInstalledPackageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateInstalledPackageResponse {
    return new CreateInstalledPackageResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateInstalledPackageResponse | PlainMessage<CreateInstalledPackageResponse> | undefined,
    b: CreateInstalledPackageResponse | PlainMessage<CreateInstalledPackageResponse> | undefined,
  ): boolean {
    return proto3.util.equals(CreateInstalledPackageResponse, a, b);
  }
}

/**
 * UpdateInstalledPackageResponse
 *
 * Response for UpdateInstalledPackage
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{"installed_package_ref": {}}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.UpdateInstalledPackageResponse
 */
export class UpdateInstalledPackageResponse extends Message<UpdateInstalledPackageResponse> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  constructor(data?: PartialMessage<UpdateInstalledPackageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.UpdateInstalledPackageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdateInstalledPackageResponse {
    return new UpdateInstalledPackageResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdateInstalledPackageResponse {
    return new UpdateInstalledPackageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdateInstalledPackageResponse {
    return new UpdateInstalledPackageResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateInstalledPackageResponse | PlainMessage<UpdateInstalledPackageResponse> | undefined,
    b: UpdateInstalledPackageResponse | PlainMessage<UpdateInstalledPackageResponse> | undefined,
  ): boolean {
    return proto3.util.equals(UpdateInstalledPackageResponse, a, b);
  }
}

/**
 * DeleteInstalledPackageResponse
 *
 * Response for DeleteInstalledPackage
 *
 * For future extensibility only.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.DeleteInstalledPackageResponse
 */
export class DeleteInstalledPackageResponse extends Message<DeleteInstalledPackageResponse> {
  constructor(data?: PartialMessage<DeleteInstalledPackageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.DeleteInstalledPackageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteInstalledPackageResponse {
    return new DeleteInstalledPackageResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteInstalledPackageResponse {
    return new DeleteInstalledPackageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteInstalledPackageResponse {
    return new DeleteInstalledPackageResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteInstalledPackageResponse | PlainMessage<DeleteInstalledPackageResponse> | undefined,
    b: DeleteInstalledPackageResponse | PlainMessage<DeleteInstalledPackageResponse> | undefined,
  ): boolean {
    return proto3.util.equals(DeleteInstalledPackageResponse, a, b);
  }
}

/**
 * GetInstalledPackageResourceRefsResponse
 *
 * Response for GetInstalledPackageResourceRefs
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetInstalledPackageResourceRefsResponse
 */
export class GetInstalledPackageResourceRefsResponse extends Message<GetInstalledPackageResourceRefsResponse> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.ResourceRef resource_refs = 2;
   */
  resourceRefs: ResourceRef[] = [];

  constructor(data?: PartialMessage<GetInstalledPackageResourceRefsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetInstalledPackageResourceRefsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "resource_refs", kind: "message", T: ResourceRef, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetInstalledPackageResourceRefsResponse {
    return new GetInstalledPackageResourceRefsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageResourceRefsResponse {
    return new GetInstalledPackageResourceRefsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetInstalledPackageResourceRefsResponse {
    return new GetInstalledPackageResourceRefsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInstalledPackageResourceRefsResponse
      | PlainMessage<GetInstalledPackageResourceRefsResponse>
      | undefined,
    b:
      | GetInstalledPackageResourceRefsResponse
      | PlainMessage<GetInstalledPackageResourceRefsResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetInstalledPackageResourceRefsResponse, a, b);
  }
}

/**
 * AvailablePackageSummary
 *
 * An AvailablePackageSummary provides a summary of a package available for installation
 * useful when aggregating many available packages.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.AvailablePackageSummary
 */
export class AvailablePackageSummary extends Message<AvailablePackageSummary> {
  /**
   * Available package reference
   *
   * A reference uniquely identifying the package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageReference available_package_ref = 1;
   */
  availablePackageRef?: AvailablePackageReference;

  /**
   * Available package name
   *
   * The name of the available package
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * Latest available version
   *
   * The latest version available for this package. Often expected when viewing
   * a summary of many available packages.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion latest_version = 3;
   */
  latestVersion?: PackageAppVersion;

  /**
   * Available package Icon URL
   *
   * A url for an icon.
   *
   * @generated from field: string icon_url = 4;
   */
  iconUrl = "";

  /**
   * Available package display name
   *
   * A name as displayed to users
   *
   * @generated from field: string display_name = 5;
   */
  displayName = "";

  /**
   * Available package short description
   *
   * A short description of the app provided by the package
   *
   * @generated from field: string short_description = 6;
   */
  shortDescription = "";

  /**
   * Available package categories
   *
   * A user-facing list of category names useful for creating richer user interfaces.
   * Plugins can choose not to implement this
   *
   * @generated from field: repeated string categories = 7;
   */
  categories: string[] = [];

  constructor(data?: PartialMessage<AvailablePackageSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.AvailablePackageSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available_package_ref", kind: "message", T: AvailablePackageReference },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "latest_version", kind: "message", T: PackageAppVersion },
    { no: 4, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AvailablePackageSummary {
    return new AvailablePackageSummary().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AvailablePackageSummary {
    return new AvailablePackageSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AvailablePackageSummary {
    return new AvailablePackageSummary().fromJsonString(jsonString, options);
  }

  static equals(
    a: AvailablePackageSummary | PlainMessage<AvailablePackageSummary> | undefined,
    b: AvailablePackageSummary | PlainMessage<AvailablePackageSummary> | undefined,
  ): boolean {
    return proto3.util.equals(AvailablePackageSummary, a, b);
  }
}

/**
 * AvailablePackageDetail
 *
 * An AvailablePackageDetail provides additional details required when
 * inspecting an individual package.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.AvailablePackageDetail
 */
export class AvailablePackageDetail extends Message<AvailablePackageDetail> {
  /**
   * Available package reference
   *
   * A reference uniquely identifying the package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageReference available_package_ref = 1;
   */
  availablePackageRef?: AvailablePackageReference;

  /**
   * Available package name
   *
   * The name of the available package
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * Available version
   *
   * The version of the package and application.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion version = 3;
   */
  version?: PackageAppVersion;

  /**
   * the url of the package repository that contains this package
   *
   * @generated from field: string repo_url = 4;
   */
  repoUrl = "";

  /**
   * the url of the "home" for the package
   *
   * @generated from field: string home_url = 5;
   */
  homeUrl = "";

  /**
   * Available package icon URL
   *
   * A url for an icon.
   *
   * @generated from field: string icon_url = 6;
   */
  iconUrl = "";

  /**
   * Available package display name
   *
   * A name as displayed to users
   *
   * @generated from field: string display_name = 7;
   */
  displayName = "";

  /**
   * Available package short description
   *
   * A short description of the app provided by the package
   *
   * @generated from field: string short_description = 8;
   */
  shortDescription = "";

  /**
   * Available package long description
   *
   * A longer description of the package, a few sentences.
   *
   * @generated from field: string long_description = 9;
   */
  longDescription = "";

  /**
   * Available package readme
   *
   * A longer README with potentially pages of formatted Markdown.
   *
   * @generated from field: string readme = 10;
   */
  readme = "";

  /**
   * Available package default values
   *
   * An example of default values used during package templating that can serve
   * as documentation or a starting point for user customization.
   *
   * @generated from field: string default_values = 11;
   */
  defaultValues = "";

  /**
   * Available package additional default values
   *
   * A package may contain additional default value files for specific scenarios,
   * such as values_production.yaml or values_dev.yaml
   *
   * @generated from field: map<string, string> additional_default_values = 17;
   */
  additionalDefaultValues: { [key: string]: string } = {};

  /**
   * @generated from field: string values_schema = 12;
   */
  valuesSchema = "";

  /**
   * source urls for the package
   *
   * @generated from field: repeated string source_urls = 13;
   */
  sourceUrls: string[] = [];

  /**
   * Available package maintainers
   *
   * List of Maintainer
   *
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.Maintainer maintainers = 14;
   */
  maintainers: Maintainer[] = [];

  /**
   * Available package categories
   *
   * A user-facing list of category names useful for creating richer user interfaces.
   * Plugins can choose not to implement this
   *
   * @generated from field: repeated string categories = 15;
   */
  categories: string[] = [];

  /**
   * Custom data added by the plugin
   *
   * A plugin can define custom details for data which is not yet, or never will
   * be specified in the core.packaging.CreateInstalledPackageRequest fields. The use
   * of an `Any` field means that each plugin can define the structure of this
   * message as required, while still satisfying the core interface.
   * See https://developers.google.com/protocol-buffers/docs/proto3#any
   *
   * @generated from field: google.protobuf.Any custom_detail = 16;
   */
  customDetail?: Any;

  constructor(data?: PartialMessage<AvailablePackageDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.AvailablePackageDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available_package_ref", kind: "message", T: AvailablePackageReference },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "message", T: PackageAppVersion },
    { no: 4, name: "repo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "home_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "readme", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "default_values", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    {
      no: 17,
      name: "additional_default_values",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 12, name: "values_schema", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "source_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "maintainers", kind: "message", T: Maintainer, repeated: true },
    { no: 15, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "custom_detail", kind: "message", T: Any },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AvailablePackageDetail {
    return new AvailablePackageDetail().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AvailablePackageDetail {
    return new AvailablePackageDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AvailablePackageDetail {
    return new AvailablePackageDetail().fromJsonString(jsonString, options);
  }

  static equals(
    a: AvailablePackageDetail | PlainMessage<AvailablePackageDetail> | undefined,
    b: AvailablePackageDetail | PlainMessage<AvailablePackageDetail> | undefined,
  ): boolean {
    return proto3.util.equals(AvailablePackageDetail, a, b);
  }
}

/**
 * InstalledPackageSummary
 *
 * An InstalledPackageSummary provides a summary of an installed package
 * useful when aggregating many installed packages.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.InstalledPackageSummary
 */
export class InstalledPackageSummary extends Message<InstalledPackageSummary> {
  /**
   * InstalledPackageReference
   *
   * A reference uniquely identifying the package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  /**
   * Name
   *
   * A name given to the installation of the package (eg. "my-postgresql-for-testing").
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * PkgVersionReference
   *
   * The package version reference defines a version or constraint limiting
   * matching package versions.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.VersionReference pkg_version_reference = 3;
   */
  pkgVersionReference?: VersionReference;

  /**
   * CurrentVersion
   *
   * The current version of the package being reconciled, which may be
   * in one of these states:
   *  - has been successfully installed/upgraded or
   *  - is currently being installed/upgraded or
   *  - has failed to install/upgrade
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion current_version = 4;
   */
  currentVersion?: PackageAppVersion;

  /**
   * Installed package icon URL
   *
   * A url for an icon.
   *
   * @generated from field: string icon_url = 5;
   */
  iconUrl = "";

  /**
   * PackageDisplayName
   *
   * The package name as displayed to users (provided by the package, eg. "PostgreSQL")
   *
   * @generated from field: string pkg_display_name = 6;
   */
  pkgDisplayName = "";

  /**
   * ShortDescription
   *
   * A short description of the package (provided by the package)
   *
   * @generated from field: string short_description = 7;
   */
  shortDescription = "";

  /**
   * LatestMatchingVersion
   *
   * Only non-empty if an available upgrade matches the specified pkg_version_reference.
   * For example, if the pkg_version_reference is ">10.3.0 < 10.4.0" and 10.3.1
   * is installed, then:
   *   * if 10.3.2 is available, latest_matching_version should be 10.3.2, but
   *   * if 10.4 is available while >10.3.1 is not, this should remain empty.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion latest_matching_version = 8;
   */
  latestMatchingVersion?: PackageAppVersion;

  /**
   * LatestVersion
   *
   * The latest version available for this package, regardless of the pkg_version_reference.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion latest_version = 9;
   */
  latestVersion?: PackageAppVersion;

  /**
   * Status
   *
   * The current status of the installed package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus status = 10;
   */
  status?: InstalledPackageStatus;

  constructor(data?: PartialMessage<InstalledPackageSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.InstalledPackageSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pkg_version_reference", kind: "message", T: VersionReference },
    { no: 4, name: "current_version", kind: "message", T: PackageAppVersion },
    { no: 5, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "pkg_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "latest_matching_version", kind: "message", T: PackageAppVersion },
    { no: 9, name: "latest_version", kind: "message", T: PackageAppVersion },
    { no: 10, name: "status", kind: "message", T: InstalledPackageStatus },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): InstalledPackageSummary {
    return new InstalledPackageSummary().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageSummary {
    return new InstalledPackageSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageSummary {
    return new InstalledPackageSummary().fromJsonString(jsonString, options);
  }

  static equals(
    a: InstalledPackageSummary | PlainMessage<InstalledPackageSummary> | undefined,
    b: InstalledPackageSummary | PlainMessage<InstalledPackageSummary> | undefined,
  ): boolean {
    return proto3.util.equals(InstalledPackageSummary, a, b);
  }
}

/**
 * InstalledPackageDetail
 *
 * An InstalledPackageDetail includes details about the installed package that are
 * typically useful when presenting a single installed package.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.InstalledPackageDetail
 */
export class InstalledPackageDetail extends Message<InstalledPackageDetail> {
  /**
   * InstalledPackageReference
   *
   * A reference uniquely identifying the installed package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageReference installed_package_ref = 1;
   */
  installedPackageRef?: InstalledPackageReference;

  /**
   * PkgVersionReference
   *
   * The package version reference defines a version or constraint limiting
   * matching package versions.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.VersionReference pkg_version_reference = 2;
   */
  pkgVersionReference?: VersionReference;

  /**
   * Installed package name
   *
   * The name given to the installed package
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * CurrentVersion
   *
   * The version of the package which is currently installed.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion current_version = 4;
   */
  currentVersion?: PackageAppVersion;

  /**
   * ValuesApplied
   *
   * The values applied currently for the installed package.
   *
   * @generated from field: string values_applied = 5;
   */
  valuesApplied = "";

  /**
   * ReconciliationOptions
   *
   * An optional field specifying data common to systems that reconcile
   * the package installation on the cluster asynchronously. In particular,
   * this specifies the service account used to perform the reconcilliation.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.ReconciliationOptions reconciliation_options = 6;
   */
  reconciliationOptions?: ReconciliationOptions;

  /**
   * Status
   *
   * The current status of the installed package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus status = 7;
   */
  status?: InstalledPackageStatus;

  /**
   * PostInstallationNotes
   *
   * Optional notes generated by package and intended for the user post installation.
   *
   * @generated from field: string post_installation_notes = 8;
   */
  postInstallationNotes = "";

  /**
   * Available package reference
   *
   * A reference to the available package for this installation.
   * Useful to lookup the package display name, icon and other info.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.AvailablePackageReference available_package_ref = 9;
   */
  availablePackageRef?: AvailablePackageReference;

  /**
   * LatestMatchingVersion
   *
   * Only non-empty if an available upgrade matches the specified pkg_version_reference.
   * For example, if the pkg_version_reference is ">10.3.0 < 10.4.0" and 10.3.1
   * is installed, then:
   *   * if 10.3.2 is available, latest_matching_version should be 10.3.2, but
   *   * if 10.4 is available while >10.3.1 is not, this should remain empty.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion latest_matching_version = 10;
   */
  latestMatchingVersion?: PackageAppVersion;

  /**
   * LatestVersion
   *
   * The latest version available for this package, regardless of the pkg_version_reference.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageAppVersion latest_version = 11;
   */
  latestVersion?: PackageAppVersion;

  /**
   * Custom data added by the plugin
   *
   * A plugin can define custom details for data which is not yet, or never will
   * be specified in the core.packaging.CreateInstalledPackageRequest fields. The use
   * of an `Any` field means that each plugin can define the structure of this
   * message as required, while still satisfying the core interface.
   * See https://developers.google.com/protocol-buffers/docs/proto3#any
   *
   * @generated from field: google.protobuf.Any custom_detail = 14;
   */
  customDetail?: Any;

  constructor(data?: PartialMessage<InstalledPackageDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.InstalledPackageDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed_package_ref", kind: "message", T: InstalledPackageReference },
    { no: 2, name: "pkg_version_reference", kind: "message", T: VersionReference },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "current_version", kind: "message", T: PackageAppVersion },
    { no: 5, name: "values_applied", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "reconciliation_options", kind: "message", T: ReconciliationOptions },
    { no: 7, name: "status", kind: "message", T: InstalledPackageStatus },
    { no: 8, name: "post_installation_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "available_package_ref", kind: "message", T: AvailablePackageReference },
    { no: 10, name: "latest_matching_version", kind: "message", T: PackageAppVersion },
    { no: 11, name: "latest_version", kind: "message", T: PackageAppVersion },
    { no: 14, name: "custom_detail", kind: "message", T: Any },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): InstalledPackageDetail {
    return new InstalledPackageDetail().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageDetail {
    return new InstalledPackageDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageDetail {
    return new InstalledPackageDetail().fromJsonString(jsonString, options);
  }

  static equals(
    a: InstalledPackageDetail | PlainMessage<InstalledPackageDetail> | undefined,
    b: InstalledPackageDetail | PlainMessage<InstalledPackageDetail> | undefined,
  ): boolean {
    return proto3.util.equals(InstalledPackageDetail, a, b);
  }
}

/**
 * Context
 *
 * A Context specifies the context of the message
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.Context
 */
export class Context extends Message<Context> {
  /**
   * Cluster
   *
   * A cluster name can be provided to target a specific cluster if multiple
   * clusters are configured, otherwise all clusters will be assumed.
   *
   * @generated from field: string cluster = 1;
   */
  cluster = "";

  /**
   * Namespace
   *
   * A namespace must be provided if the context of the operation is for a resource
   * or resources in a particular namespace.
   * For requests to list items, not including a namespace here implies that the context
   * for the request is everything the requesting user can read, though the result can
   * be filtered by any filtering options of the request. Plugins may choose to return
   * Unimplemented for some queries for which we do not yet have a need.
   *
   * @generated from field: string namespace = 2;
   */
  namespace = "";

  constructor(data?: PartialMessage<Context>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.Context";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cluster", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Context {
    return new Context().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Context {
    return new Context().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Context {
    return new Context().fromJsonString(jsonString, options);
  }

  static equals(
    a: Context | PlainMessage<Context> | undefined,
    b: Context | PlainMessage<Context> | undefined,
  ): boolean {
    return proto3.util.equals(Context, a, b);
  }
}

/**
 * AvailablePackageReference
 *
 * An AvailablePackageReference has the minimum information required to uniquely
 * identify an available package. This is re-used on the summary and details of an
 * available package.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.AvailablePackageReference
 */
export class AvailablePackageReference extends Message<AvailablePackageReference> {
  /**
   * Available package context
   *
   * The context (cluster/namespace) for the package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * Available package identifier
   *
   * The fully qualified identifier for the available package
   * (ie. a unique name for the context). For some packaging systems
   * (particularly those where an available package is backed by a CR) this
   * will just be the name, but for others such as those where an available
   * package is not backed by a CR (eg. standard helm) it may be necessary
   * to include the repository in the name or even the repo namespace
   * to ensure this is unique.
   * For example two helm repositories can define
   * an "apache" chart that is available globally, the names would need to
   * encode that to be unique (ie. "repoA:apache" and "repoB:apache").
   *
   * @generated from field: string identifier = 2;
   */
  identifier = "";

  /**
   * Plugin for the available package
   *
   * The plugin used to interact with this available package.
   * This field should be omitted when the request is in the context of a specific plugin.
   *
   * @generated from field: kubeappsapis.core.plugins.v1alpha1.Plugin plugin = 3;
   */
  plugin?: Plugin;

  constructor(data?: PartialMessage<AvailablePackageReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.AvailablePackageReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "plugin", kind: "message", T: Plugin },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AvailablePackageReference {
    return new AvailablePackageReference().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AvailablePackageReference {
    return new AvailablePackageReference().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AvailablePackageReference {
    return new AvailablePackageReference().fromJsonString(jsonString, options);
  }

  static equals(
    a: AvailablePackageReference | PlainMessage<AvailablePackageReference> | undefined,
    b: AvailablePackageReference | PlainMessage<AvailablePackageReference> | undefined,
  ): boolean {
    return proto3.util.equals(AvailablePackageReference, a, b);
  }
}

/**
 * Maintainer
 *
 * Maintainers for the package.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.Maintainer
 */
export class Maintainer extends Message<Maintainer> {
  /**
   * Maintainer name
   *
   * A maintainer name
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Maintainer email
   *
   * A maintainer email
   *
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<Maintainer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.Maintainer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Maintainer {
    return new Maintainer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Maintainer {
    return new Maintainer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Maintainer {
    return new Maintainer().fromJsonString(jsonString, options);
  }

  static equals(
    a: Maintainer | PlainMessage<Maintainer> | undefined,
    b: Maintainer | PlainMessage<Maintainer> | undefined,
  ): boolean {
    return proto3.util.equals(Maintainer, a, b);
  }
}

/**
 * FilterOptions
 *
 * FilterOptions available when requesting summaries
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.FilterOptions
 */
export class FilterOptions extends Message<FilterOptions> {
  /**
   * Text query
   *
   * Text query for the request
   *
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * Categories
   *
   * Collection of categories for the request
   *
   * @generated from field: repeated string categories = 2;
   */
  categories: string[] = [];

  /**
   * Repositories
   *
   * Collection of repositories where the packages belong to
   *
   * @generated from field: repeated string repositories = 3;
   */
  repositories: string[] = [];

  /**
   * Package version
   *
   * Package version for the request
   *
   * @generated from field: string pkg_version = 4;
   */
  pkgVersion = "";

  /**
   * App version
   *
   * Packaged app version for the request
   *
   * @generated from field: string app_version = 5;
   */
  appVersion = "";

  constructor(data?: PartialMessage<FilterOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.FilterOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "repositories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "pkg_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FilterOptions {
    return new FilterOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FilterOptions {
    return new FilterOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FilterOptions {
    return new FilterOptions().fromJsonString(jsonString, options);
  }

  static equals(
    a: FilterOptions | PlainMessage<FilterOptions> | undefined,
    b: FilterOptions | PlainMessage<FilterOptions> | undefined,
  ): boolean {
    return proto3.util.equals(FilterOptions, a, b);
  }
}

/**
 * PaginationOptions
 *
 * The PaginationOptions based on the example proto at:
 * https://cloud.google.com/apis/design/design_patterns#list_pagination
 * just encapsulated in a message so it can be reused on different request messages.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PaginationOptions
 */
export class PaginationOptions extends Message<PaginationOptions> {
  /**
   * Page token
   *
   * The client uses this field to request a specific page of the list results.
   *
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  /**
   * Page size
   *
   * Clients use this field to specify the maximum number of results to be
   * returned by the server. The server may further constrain the maximum number
   * of results returned in a single page. If the page_size is 0, the server
   * will decide the number of results to be returned.
   *
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<PaginationOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PaginationOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationOptions {
    return new PaginationOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationOptions {
    return new PaginationOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaginationOptions {
    return new PaginationOptions().fromJsonString(jsonString, options);
  }

  static equals(
    a: PaginationOptions | PlainMessage<PaginationOptions> | undefined,
    b: PaginationOptions | PlainMessage<PaginationOptions> | undefined,
  ): boolean {
    return proto3.util.equals(PaginationOptions, a, b);
  }
}

/**
 * InstalledPackageReference
 *
 * An InstalledPackageReference has the minimum information required to uniquely
 * identify an installed package.
 *
 * TODO: add example for API docs
 * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
 *   example: '{}'
 * };
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.InstalledPackageReference
 */
export class InstalledPackageReference extends Message<InstalledPackageReference> {
  /**
   * Installed package context
   *
   * The context (cluster/namespace) for the package.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * The fully qualified identifier for the installed package
   * (ie. a unique name for the context).
   *
   * @generated from field: string identifier = 2;
   */
  identifier = "";

  /**
   * The plugin used to identify and interact with the installed package.
   * This field can be omitted when the request is in the context of a specific plugin.
   *
   * @generated from field: kubeappsapis.core.plugins.v1alpha1.Plugin plugin = 3;
   */
  plugin?: Plugin;

  constructor(data?: PartialMessage<InstalledPackageReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.InstalledPackageReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "plugin", kind: "message", T: Plugin },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): InstalledPackageReference {
    return new InstalledPackageReference().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageReference {
    return new InstalledPackageReference().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageReference {
    return new InstalledPackageReference().fromJsonString(jsonString, options);
  }

  static equals(
    a: InstalledPackageReference | PlainMessage<InstalledPackageReference> | undefined,
    b: InstalledPackageReference | PlainMessage<InstalledPackageReference> | undefined,
  ): boolean {
    return proto3.util.equals(InstalledPackageReference, a, b);
  }
}

/**
 * VersionReference
 *
 * A VersionReference defines a version or constraint limiting matching versions.
 * The reason it is a separate message is so that in the future we can add other
 * fields as necessary (such as something similar to Carvel's `prereleases` option
 * to its versionSelection).
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.VersionReference
 */
export class VersionReference extends Message<VersionReference> {
  /**
   * Version
   *
   * The format of the version constraint depends on the backend. For example,
   * for a flux v2 and Carvel it's a semver expression, such as ">=10.3 < 10.4"
   *
   * @generated from field: string version = 1;
   */
  version = "";

  constructor(data?: PartialMessage<VersionReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.VersionReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VersionReference {
    return new VersionReference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VersionReference {
    return new VersionReference().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VersionReference {
    return new VersionReference().fromJsonString(jsonString, options);
  }

  static equals(
    a: VersionReference | PlainMessage<VersionReference> | undefined,
    b: VersionReference | PlainMessage<VersionReference> | undefined,
  ): boolean {
    return proto3.util.equals(VersionReference, a, b);
  }
}

/**
 * InstalledPackageStatus
 *
 * An InstalledPackageStatus reports on the current status of the installation.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus
 */
export class InstalledPackageStatus extends Message<InstalledPackageStatus> {
  /**
   * Ready
   *
   * An indication of whether the installation is ready or not
   *
   * @generated from field: bool ready = 1;
   */
  ready = false;

  /**
   * Reason
   *
   * An enum indicating the reason for the current status.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus.StatusReason reason = 2;
   */
  reason = InstalledPackageStatus_StatusReason.UNSPECIFIED;

  /**
   * UserReason
   *
   * Optional text to return for user context, which may be plugin specific.
   *
   * @generated from field: string user_reason = 3;
   */
  userReason = "";

  constructor(data?: PartialMessage<InstalledPackageStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ready", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    {
      no: 2,
      name: "reason",
      kind: "enum",
      T: proto3.getEnumType(InstalledPackageStatus_StatusReason),
    },
    { no: 3, name: "user_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): InstalledPackageStatus {
    return new InstalledPackageStatus().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageStatus {
    return new InstalledPackageStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): InstalledPackageStatus {
    return new InstalledPackageStatus().fromJsonString(jsonString, options);
  }

  static equals(
    a: InstalledPackageStatus | PlainMessage<InstalledPackageStatus> | undefined,
    b: InstalledPackageStatus | PlainMessage<InstalledPackageStatus> | undefined,
  ): boolean {
    return proto3.util.equals(InstalledPackageStatus, a, b);
  }
}

/**
 * StatusReason
 *
 * Generic reasons why an installed package may be ready or not.
 * These should make sense across different packaging plugins.
 *
 * @generated from enum kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus.StatusReason
 */
export enum InstalledPackageStatus_StatusReason {
  /**
   * @generated from enum value: STATUS_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_REASON_INSTALLED = 1;
   */
  INSTALLED = 1,

  /**
   * @generated from enum value: STATUS_REASON_UNINSTALLED = 2;
   */
  UNINSTALLED = 2,

  /**
   * @generated from enum value: STATUS_REASON_FAILED = 3;
   */
  FAILED = 3,

  /**
   * @generated from enum value: STATUS_REASON_PENDING = 4;
   */
  PENDING = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(InstalledPackageStatus_StatusReason)
proto3.util.setEnumType(
  InstalledPackageStatus_StatusReason,
  "kubeappsapis.core.packages.v1alpha1.InstalledPackageStatus.StatusReason",
  [
    { no: 0, name: "STATUS_REASON_UNSPECIFIED" },
    { no: 1, name: "STATUS_REASON_INSTALLED" },
    { no: 2, name: "STATUS_REASON_UNINSTALLED" },
    { no: 3, name: "STATUS_REASON_FAILED" },
    { no: 4, name: "STATUS_REASON_PENDING" },
  ],
);

/**
 * ReconciliationOptions
 *
 * ReconciliationOptions enable specifying standard fields for backends that continuously
 * reconcile a package install as new matching versions are released. Most of the naming
 * is from the flux HelmReleaseSpec though it maps directly to equivalent fields on Carvel's
 * InstalledPackage.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.ReconciliationOptions
 */
export class ReconciliationOptions extends Message<ReconciliationOptions> {
  /**
   * Reconciliation Interval
   *
   * The interval with which the package is checked for reconciliation (in time+unit)
   *
   * @generated from field: string interval = 1;
   */
  interval = "";

  /**
   * Suspend
   *
   * Whether reconciliation should be suspended until otherwise enabled.
   * This can be utilized to e.g. temporarily ignore chart changes, and
   * prevent a Helm release from getting upgraded
   *
   * @generated from field: bool suspend = 2;
   */
  suspend = false;

  /**
   * ServiceAccountName
   *
   * A name for a service account in the same namespace which should be used
   * to perform the reconciliation.
   *
   * @generated from field: string service_account_name = 3;
   */
  serviceAccountName = "";

  constructor(data?: PartialMessage<ReconciliationOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.ReconciliationOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "interval", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "suspend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "service_account_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ReconciliationOptions {
    return new ReconciliationOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReconciliationOptions {
    return new ReconciliationOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ReconciliationOptions {
    return new ReconciliationOptions().fromJsonString(jsonString, options);
  }

  static equals(
    a: ReconciliationOptions | PlainMessage<ReconciliationOptions> | undefined,
    b: ReconciliationOptions | PlainMessage<ReconciliationOptions> | undefined,
  ): boolean {
    return proto3.util.equals(ReconciliationOptions, a, b);
  }
}

/**
 * Package AppVersion
 *
 * PackageAppVersion conveys both the package version and the packaged app version.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageAppVersion
 */
export class PackageAppVersion extends Message<PackageAppVersion> {
  /**
   * Package version
   *
   * Version of the package itself
   *
   * @generated from field: string pkg_version = 1;
   */
  pkgVersion = "";

  /**
   * Application version
   *
   * Version of the packaged application
   *
   * @generated from field: string app_version = 2;
   */
  appVersion = "";

  constructor(data?: PartialMessage<PackageAppVersion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageAppVersion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pkg_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackageAppVersion {
    return new PackageAppVersion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackageAppVersion {
    return new PackageAppVersion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackageAppVersion {
    return new PackageAppVersion().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageAppVersion | PlainMessage<PackageAppVersion> | undefined,
    b: PackageAppVersion | PlainMessage<PackageAppVersion> | undefined,
  ): boolean {
    return proto3.util.equals(PackageAppVersion, a, b);
  }
}

/**
 * Resource reference
 *
 * A reference to a Kubernetes resource related to a specific installed package.
 * The context (cluster) for each resource is that of the related
 * installed package.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.ResourceRef
 */
export class ResourceRef extends Message<ResourceRef> {
  /**
   * The APIVersion directly from the resource has the group and version, eg. "apps/v1"
   * or just the version for core resources.
   *
   * @generated from field: string api_version = 1;
   */
  apiVersion = "";

  /**
   * The Kind directly from the templated manifest. Together with the APIVersion this
   * forms the GroupVersionKind.
   *
   * @generated from field: string kind = 2;
   */
  kind = "";

  /**
   * The name of the specific resource in the context of the installed package.
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * The namespace of the specific resource in the context of the installed
   * package. In most cases this will be identical to the namespace of the
   * installed package. Exceptions will be non-namespaced resources and packages
   * that install resources in other namespaces for special reasons.
   *
   * @generated from field: string namespace = 4;
   */
  namespace = "";

  constructor(data?: PartialMessage<ResourceRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.ResourceRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceRef {
    return new ResourceRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceRef {
    return new ResourceRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceRef {
    return new ResourceRef().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResourceRef | PlainMessage<ResourceRef> | undefined,
    b: ResourceRef | PlainMessage<ResourceRef> | undefined,
  ): boolean {
    return proto3.util.equals(ResourceRef, a, b);
  }
}
